<template lang="html">
  <input
    :class="getCssClasses"
    :disabled="disabled"
    :type="type || 'button'"
    :value="text"
    @click="clickButton($event)"
  />
</template>

<script>
export default {
  props: {
    type: String,
    text: String,
    disabled: Boolean,
    isInProgress: Boolean,
    klass: {
      type: String
    }
  },

  computed: {
    getCssClasses() {
      const result = `${this.klass} ze-button`;
      if(!this.isInProgress) { return result }

      result.push(' progress');
      return result;
    }
  },

  methods: {
    clickButton($event) {
      $event.target.focus();
      this.$emit('onClick', $event);
    }
  }
}
</script>
