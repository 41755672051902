<template lang="html">
  <div class="ze-input">
    <div class="labels" :class="{error: isError}">
      <label class="left" :for="id" v-if="labelLeft">
        {{labelLeft}}
      </label>
      <label v-on:click="toggleHiddenChar" class="right" for="text-input" v-if="labelRight">
        {{labelRight}}
      </label>
    </div>

    <input ref="input" v-validate="'required|email'"
      :id="id"
      :class="{'correct': fields['patient[email]'] && fields['patient[email]'].dirty && fields['patient[email]'].valid, error: isError }"
      :name="name"
      type="email"
      :placeholder="placeholder"
      v-model="mutatedValue"
      :autofocus="autofocus"
      aria-label="'Enter your email address'"
      :autocomplete="autocomplete"/>
    <div class="error-message" v-html="errorMessage"></div>
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'placeholder',
    'labelLeft', // labels around the input
    'labelRight',
    'labelBottom', // is hidden if errorMessage is shown
    'type',
    'errorMessage',
    'isError',
    'value',
    'name',
    'autocomplete',
    'autofocus'
  ],
  mounted() {
    if(this.$props.autofocus) {
      this.$refs.input.focus();
    }
  },
  data: function () {
    return {
      mutatedValue: this.value
    }
  }
}
</script>
