<template lang="html">
  <div class="ze-input">
    <div class="labels" :class="{error: isError}">
      <label class="left" for="nhs-number">
        NHS NUMBER
      </label>
    </div>
    <div class="nhs" :class="{error: isError}">
      <input
        v-model=nhsNumber
        id="nhs-number"
        @input="handleInput"
        pattern="[0-9\-]+"
        name="nhs_number"
        placeholder="Enter your NHS Number"
        ref="nhs_number"
        inputmode="tel"
        type="tel"
        aria-label="'Enter your NHS Number'"
      />
    </div>
    <div class="error-message" v-html="errorMessage"></div>
  </div>
</template>

<script>

import $_nhsNumber from '../mixins/$_nhs-number.js';

export default {
  mixins: [$_nhsNumber],
  props: {
    autofocus: Boolean,
    nhs: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      nhsNumber: this.nhs
    }
  },

  mounted() {
    if(this.autofocus) {
      this.focusInput();
    }
  },

  methods: {
    handleInput(e) {
      const { nhsNumber } = this;
      this.limitLength(nhsNumber, e);
      this.nhsNumber = this.prettyPrintNHS(nhsNumber);
      this.dynamicDisable();
      this.$emit('input', this.nhsNumber);
    },

    focusInput() {
      this.$refs.nhs_number.focus();
    }
  }
}

</script>
