<template lang="html">
  <div class="ze-input">
    <div class="labels" :class="{error: isError}">
      <label for="password-input" class="left">
        {{labelLeft || 'PASSWORD'}}
      </label>
      <label v-on:click="toggleHiddenChar" for="text-input" class="right">
        Show
      </label>
    </div>
    <input
      id="password-input"
      ref="input"
      :autofocus="autofocus"
      type="password"
      :name="name || 'text-input'"
      v-model="password"
      :class="{correct: isCorrect, error: isError}"
      :placeholder="placeholder || 'Enter a password'"
      :aria-label="placeholder || 'Enter a password'"
      :autocomplete="autocomplete">
    </input>
    <div id="passwordstrength-0">
      <div class="strength green">
        <div>Password: Weak</div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </div>
    <div id="passwordstrength-1">
      <div class="strength green">
        <div>Password: Fair</div>
        <div class="item active"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </div>
    <div id="passwordstrength-2">
      <div class="strength green">
        <div>Password: Moderate</div>
        <div class="item active"></div>
        <div class="item active"></div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </div>
    <div id="passwordstrength-3">
      <div class="strength green">
        <div>Password: Strong</div>
        <div class="item active"></div>
        <div class="item active"></div>
        <div class="item active"></div>
        <div class="item"></div>
      </div>
    </div>
    <div id="passwordstrength-4">
      <div class="strength green">
        <div>Password: Very Strong</div>
        <div class="item active"></div>
        <div class="item active"></div>
        <div class="item active"></div>
        <div class="item active"></div>
      </div>
    </div>
    <div class="error-message" v-html="errorMessage"></div>
  </div>
</template>

<script>
var zxcvbn = require('zxcvbn');
var togglebool;

export default {
  props: [
    'name',
    'isCorrect',
    'isError',
    'errorMessage',
    'labelLeft',
    'placeholder',
    'autocomplete',
    'autofocus',
  ],
  data: function () {
    return {
      password: '',
      passwordStrength: 0
    }
  },
  watch: {
    password: function (val) {
      var allPasswordEl = document.querySelectorAll('*[id^="passwordstrength"]');
      var i
      for (i = 0; i < allPasswordEl.length; i++) {
        allPasswordEl[i].style.display = 'none'
      }
      var passwordStrength = zxcvbn(val).score
      document.getElementById("passwordstrength-" + passwordStrength).style.display = 'block'
    }
  },
  mounted() {
    if(this.$props.autofocus) {
      setTimeout(this.focusInput, 100);
    }
  },
  methods: {
    focusInput: function() {
      this.$refs.input.focus();
    },
    toggleHiddenChar: function (event) {
      togglebool = !togglebool;
      if (togglebool) {
        document.getElementById("password-input").setAttribute('type', 'text');
        event.srcElement.textContent = "Hide";
      } else {
        document.getElementById("password-input").setAttribute('type', 'password');
        event.srcElement.textContent = "Show";
      }
    }
  }
}
</script>
