<template lang="html">
  <div id="popup-wrapper"
       @keyup="menuToggle"
       aria-modal="true"
       aria-labelledby="label"
       aria-describedby="description"
       class="popup-wrapper"
       tabindex="-1" role="dialog"
       v-if="id === $root.visiblePopupId"
       @keydown.tab="focusHandler">
    <div class="popup">
      <slot></slot>
    </div>
  </div>
</template>

<script>


export default {
  props: [
    'id'
  ],
  data () {
    return {
      focusableElements: []
    }
  },
  created: function() {
    this.$parent.$on('show', this.paperlessPreferencesChallenge);
  },
  methods: {
    paperlessPreferencesChallenge: function() {
      if (this.id == 'paperPreferencesChangePopup') {
        let csrf_token = document.querySelector('meta[name="csrf-token"]')
                                 .getAttribute('content');
        $.ajax({
          url:     '/api/domain_event_logs',
          method:  'post',
          data:    {
            event_type: 'paperless_preferences_challenge',
            triggered_by: 'patient'
          },
          headers: { 'X-CSRF-Token': csrf_token }
        })
      }
    },
    readPopUpOnly: function () {
      let hiddenElementsList = document.querySelectorAll('[aria-hidden="false"]');
      let shownElementsList = document.querySelectorAll('[aria-hidden="true"]');
      let areNonPopUpElementsShown = this.$root.visiblePopupId !== '';
      hiddenElementsList.forEach(function(element){ element.setAttribute('aria-hidden', areNonPopUpElementsShown); });
      shownElementsList.forEach(function(element){ element.setAttribute('aria-hidden', areNonPopUpElementsShown); });
    },
    focusOnPopUp: function () {
      if (document.getElementById("popup-wrapper")) {
        document.getElementById("popup-wrapper").focus();
      }
    },
    menuToggle (e) {
      if (e.keyCode === 27) {
        this.$root.visiblePopupId = "close"
      }
    },
    getFocusableElements(popup) {
      return popup.find('button:visible, [href]:visible, input:visible, select:visible, textarea:visible')
    },
    focusHandler(event) {
      if (!this.focusableElements.length) return

      if (this.focusableElements.length == 1) {
        event.preventDefault()
        return
      }

      let activeElement = document.activeElement
      let firstFocusableElement = this.focusableElements[0]
      let lastFocusedElement    = this.focusableElements[this.focusableElements.length - 1]
      let element;

      if (activeElement === firstFocusableElement && event.shiftKey) {
        element = lastFocusedElement
      } else if (activeElement === lastFocusedElement && !event.shiftKey) {
        element = firstFocusableElement
      }

      if (element) {
        event.preventDefault()
        element.focus()
      }
    },
  },
  updated: function() {
    this.readPopUpOnly();
    this.focusOnPopUp();
    let popup = $('.popup')
    if (popup.length) {
      this.focusableElements = this.getFocusableElements(popup)
      if (this.focusableElements.length !== 0) { this.focusableElements[0].focus() }
    }
  }
}

</script>
