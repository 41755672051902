<template lang="html">
  <div>
    <div class="form">
      <div class="form-body">
        <input
          type="hidden"
          name="patient[accept_terms_and_conditions]"
          :value=false
        />
        <ze-checkbox
          id="accept-terms-and-conditions"
          label="I have read and agreed to the terms and conditions"
          name="patient[accept_terms_and_conditions]"
          input_value="true"
        />
        <div class="error-message" v-if="isError" v-text="errorMessage" />
      </div>
    </div>
    <div class="text-center">
      <ze-button
        klass="trackable"
        text="Next"
        type="submit"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isError: Boolean,
    errorMessage: String
  }
};
</script>
