<template lang="html">
  <div class="ze-not-found-step">
    <ze-nhs
      v-if="isNhsNumberVisible"
      :value="nhsNumber"
      :error-message="nhsErrorMessage"
      :is-error="isNhsError"
      v-on:nhs-completed="updateNhsCompleted"
      autofocus="autofocus"
    ></ze-nhs>
    <ze-date
      :date="dateOfBirth"
      :error-message="dateErrorMessage"
      :is-error="isDateError"
      v-on:date-completed="updateDateCompleted"
    ></ze-date>
    <div class="ze-buttons bottom-fixed">
      <ze-button
        id="submit-btn-id"
        :disabled="!isSubmitButtonEnabled"
        klass="trackable"
        :text="submitButtonText"
        type="submit"
      ></ze-button>
      <a id="record-not-found-logout-btn" :href="logoutUrl" v-if="notFoundCount > 1" class="trackable">
        <ze-button
            klass="white"
          text="Exit registration"
        ></ze-button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'dateErrorMessage',
    'dateOfBirth',
    'isDateError',
    'isNhsError',
    'logoutUrl',
    'nhsNumber',
    'nhsErrorMessage',
    'notFoundCount',
    'tokenRegistration',
    'buttonText'
  ],
  data () {
    return {
      isNhsCompleted: false,
      isDateCompleted: false
    }
  },
  computed: {
    isSubmitButtonEnabled () {
      return (this.tokenRegistration || this.isNhsCompleted) && this.isDateCompleted
    },
    isNhsNumberVisible () {
      return (this.tokenRegistration != 'true')
    },
    submitButtonText () {
      return (this.buttonText || 'Next');
    }
  },
  methods: {
    updateDateCompleted: function (isDateCompleted) {
      this.isDateCompleted = isDateCompleted
    },
    updateNhsCompleted: function (isNhsCompleted) {
      this.isNhsCompleted = isNhsCompleted
    }
  }
}
</script>
