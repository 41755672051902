<template lang="html">
  <div class="ze-date-of-birth-step">
    <ze-date
      :date="date"
      :error-message="errorMessage"
      :is-error="isError"
      v-on:date-completed="updateSubmitButton"
      autofocus="autofocus"
    ></ze-date>
    <ze-button
      id="dob-form"
      klass="bottom-fixed trackable"
      :disabled="!isSubmitButtonEnabled"
      text="Next"
      type="submit"
    ></ze-button>
  </div>
</template>

<script>
export default {
  props: [
    'date',
    'isError',
    'errorMessage'
  ],
  data () {
    return {
      isSubmitButtonEnabled: false
    }
  },
  methods: {
    updateSubmitButton: function (isDateCompleted) {
      this.isSubmitButtonEnabled = isDateCompleted
    }
  }
}
</script>
