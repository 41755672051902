<template lang="html">
  <div>
    <div class="date" :class="{error: isError}">
      <input
        v-model=dob
        id="patient-date-of-birth"
        name="patient[date_of_birth]"
        v-on:input="prettyPrintDob"
        v-on:keypress="limitLength"
        placeholder="DD/MM/YYYY"
        inputmode="numeric"
        type="tel"
        maxlength="10"
        pattern="[0-9\/]+"
        aria-label="'Enter your date of birth'"
        autocomplete="off"
        :autofocus="autofocus"
      />
    </div>
    <div class="ze-desktop-input">
      <div class="error-message" v-html="errorMessage"></div>
    </div>
  </div>
</template>

<script>

import $_date from '../mixins/$_date.js';

export default {
  mixins: [$_date],
  props: ['autofocus'],
}

</script>
