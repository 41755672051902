<template lang="html">
  <div class="checkbox-wrapper">
    <loading
      :active="isLoading"
      :loader="'spinner'"
      :color="'#2FA2CE'"
      :height="30"
      v-if="isLoading"
    />
    <template v-else>
      <input
        :id="id"
        class="ze-checkbox checkbox"
        type="checkbox"
        :value="input_value || 'on'"
        :name="name || 'ze-checkbox'"
        :checked="value"
        @click="$emit('onChange', !value)"
        :style="styles"
      />
      <label
        class="no-bottom-margin ze-checkbox-label"
        style="margin-bottom: 0"
        :for="id"
        v-text="label"
      >
      </label>
    </template>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

export default {
  props: {
    id: String,
    value: Boolean,
    label: String,
    name: String,
    input_value: String,
    onChange: Function,
    isLoading: Boolean,
    styles: {
      type: Object,
      default: function () {
        return {
          height: '25px',
          width: '25px',
          minWidth: '24px',
        };
      },
    },
  },
  components: {
    Loading,
  },
};
</script>
