<template lang="html">
  <div class="zl-menu extended">
    <a
      id="menu-toggle-link"
      ref="menuButton"
      aria-controls="menu"
      :aria-expanded="isMenuVisible"
      aria-haspopup="true"
      aria-label="Click this to open the menu"
      role="button"
      tabindex="0"
      @click="toggle"
      @keyup="menuToggle"
    >
      <div
        aria-hidden="false"
        aria-label="Click this to open the menu"
        class="menu-icon"
        role="img"
      >
        <div
          v-if="unreadNotificationsCount"
          class="notification-circle hamburger-circle"
        >
          <span class="notification-count text-white">
            {{ unreadNotificationsCount <= 9 ? unreadNotificationsCount : '9+' }}
          </span>
        </div>
      </div>
    </a>
    <transition name="slide">
      <div
        v-show="isMenuVisible"
        id="menu"
        class="menu"
        role="menu"
        @keyup="menuToggle"
      >
        <div class="menu-wrap">
          <a
            aria-label="Close menu"
            class="zl-close"
            href="#"
            role="button"
            tabindex="0"
            @click="toggle"
          ></a>

          <a
            v-for="item in items"
            :key="item.href"
            :aria-label="item.ariaLabel"
            class="zl-menuitem"
            :class="iconTranslationMap[item.iconName] || item.iconName"
            :href="item.href"
            role="menuitem"
          >
            {{ item.label }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isMenuVisible: false,
      iconTranslationMap: {
        bookmark: 'guidance',
        bell: 'notifications',
        shortText: 'message',
        calendar: 'appointment',
        document: 'paper',
        questionnaire: 'questionnaires',
        healthRecord: 'clinic-record',
        user: 'profile',
        help: 'question',
        hospital: 'clinic-pin',
        messaging: 'message',
        linkExternal: 'zl-logout-link',
      },
    };
  },

  computed: {
    unreadNotificationsCount() {
      for (const item of this.items) {
        if (item.label === 'Notifications') {
          return item.unreadCount;
        }
      }

      return 0;
    },
  },

  methods: {
    toggle() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    menuToggle(e) {
      if (e.keyCode === 13) {
        // ENTER pressed
        this.toggle();
      } else if (e.keyCode === 27) {
        // ESC closes the menu
        this.isMenuVisible = false;
        this.$refs.menuButton.focus();
      }
    },
  },
};
</script>
