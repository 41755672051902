<template lang="html">
 <div class="ze-change-password">
   <div class="ze-desktop-change-password-form" v-if="isDesktop">
     <div class="title text-center"><h1 id='title'>Change password</h1></div>
     <div class="main-text text-center">Please enter your current password before entering a new password</div>
     <div class="form small">
       <div class="form-group">
         <div class="title">
           <label for='inputCurrentPassword'>
             Enter your CURRENT password
           </label>
         </div>
         <ze-desktop-password
           id='inputCurrentPassword'
           name="patient[current_password]"
           placeholder="Type password"
           :is-error="fieldErrors['current_password']['has_error']"
           :error-message="fieldErrors['current_password']['message']"
           v-validate="'required'"
           autocomplete='current-password'
           autofocus="autofocus"
         ></ze-desktop-password>
       </div>
     </div>
     <div class="form small">
       <div class="form-group">
         <div class="title">
           <label for='inputNewPassword'>
             Enter a new password
           </label>
         </div>
         <ze-desktop-password
           id='inputNewPassword'
           :is-strength-visible="true"
           name="patient[password]"
           placeholder="Enter your password"
           v-bind:is-error="fieldErrors['password']['has_error']"
           v-bind:error-message="fieldErrors['password']['message']"
           v-validate="'required'"
           autocomplete='new-password'
         ></ze-desktop-password>
       </div>
       <div class="form-group">
         <div class="title">
           <label for='inputPasswordConfirmation'>
             Retype your new password
           </label>
         </div>
         <ze-desktop-password
           id='inputPasswordConfirmation'
           name="patient[password_confirmation]"
           placeholder="Enter your password"
           v-bind:is-error="fieldErrors['password_confirmation']['has_error']"
           v-bind:error-message="fieldErrors['password_confirmation']['message']"
           v-validate="'required'"
           autocomplete='new-password'
         ></ze-desktop-password>
       </div>
     </div>
     <div class="text-center">
       <ze-button klass="bigger trackable" type="submit" text="Change Password"></ze-button>
     </div>
     <div class="text-center">
       <a class="ze-button bigger white last-page-button" href="/patients/edit">Cancel</a>
     </div>
   </div>
   <div class="ze-mobile-change-password-form" v-if="!isDesktop">
      <ze-input
        id='inputCurrentPassword'
        type="password"
        label-left="ENTER CURRENT PASSWORD"
        label-right="Show"
        placeholder="Enter a password"
        name="patient[current_password]"
        :is-error="fieldErrors['current_password']['has_error']"
        :error-message="fieldErrors['current_password']['message']"
        v-validate="'required'"
        is-parent-validation="true"
        data-vv-value-path="validatedField"
        autocomplete='current-password'
        autofocus="autofocus"
      ></ze-input>
      <ze-password-indicator
        id='inputNewPassword'
        type="password"
        label-left="ENTER NEW PASSWORD"
        name="patient[password]"
        :is-error="fieldErrors['password']['has_error']"
        :error-message="fieldErrors['password']['message']"
        v-validate="'required'"
        data-vv-value-path="password"
        autocomplete='new-password'
      ></ze-password-indicator>
      <ze-input
        id='inputPasswordConfirmation'
        type="password"
        label-left="RE-TYPE NEW PASSWORD"
        label-right="Show"
        placeholder="Enter a password"
        name="patient[password_confirmation]"
        :is-error="fieldErrors['password_confirmation']['has_error']"
        :error-message="fieldErrors['password_confirmation']['message']"
        v-validate="'required'"
        is-parent-validation="true"
        data-vv-value-path="validatedField"
        autocomplete='new-password'
      ></ze-input>
      <ze-button type="submit" text="Change Password" class="trackable"></ze-button>
      <a class="ze-button transparent" href="/patients/edit">
        Cancel
      </a>
   </div>
  </div>
</template>


<script>
export default {
  props: ['initialFieldErrors', 'id', 'formId', 'isDesktop'],
  data: function () {
    return { fieldErrors: this.initialFieldErrors }
  },
  methods: {
    validateInputs: function(e) {
      this.$validator.validateAll();
      if(this.errors.any()) {
        e.preventDefault();
        e.stopPropagation();
        this.processErrors(this.errors);
      }
    },
    processErrors: function(errors) {
      var fields = Object.keys(this.fieldErrors);
      for (var i = 0, len = fields.length; i < len; i++) {
        if (errors.has('patient[' + fields[i] + ']')) {
          this.fieldErrors[fields[i]]['message'] = "can't be blank";
          this.fieldErrors[fields[i]]['has_error'] = true;
        } else {
          this.fieldErrors[fields[i]]['message'] = '';
          this.fieldErrors[fields[i]]['has_error'] = false;
        }
      }

    }
  },
  mounted: function () {
    document.getElementById(this.formId)
      .addEventListener('submit', this.validateInputs);
  }
}
</script>
