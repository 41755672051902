<template lang="html">
  <div class="ze-date" :class="{error: isError}">
    <div class="label">
      DATE OF BIRTH
    </div>
    <br>
    <div class="label small">
      For example: 31  12  1970
    </div>
    <br>
    <input
      v-model=dob
      name="patient[date_of_birth]"
      v-on:input="prettyPrintDob();dynamicDisable()"
      v-on:keypress="limitLength"
      placeholder="DD/MM/YYYY"
      ref="bday"
      inputmode="tel"
      type="tel"
      maxlength="10"
      pattern="[0-9\/]+"
      aria-label="'Enter your date of birth'"
      autocomplete="off"
    />
    <div v-if="isError" class="error-icon"></div>
    <div v-if="isError && errorMessage" class="error-message">
      {{errorMessage}}
    </div>
  </div>
</template>

<script>

import $_date from '../mixins/$_date.js';

export default {
  mixins: [$_date],
  props: ['autofocus'],
  mounted() {
    if(this.$props.autofocus) {
      this.focusInput();
    }
  },
  methods: {
    focusInput() {
      this.$refs.bday.focus();
    }
  }
}
</script>
