<template lang="html">
  <div class="ze-nhs-number-step">
    <ze-nhs
      :value="value"
      :error-message="errorMessage"
      :is-error="isError"
      v-on:nhs-completed="updateSubmitButton"
      autofocus="autofocus"
    ></ze-nhs>
    <ze-button
      klass="bottom-fixed trackable"
      :disabled="!isSubmitButtonEnabled"
      text="Next"
      type="submit"
    ></ze-button>
  </div>
</template>

<script>
export default {
  props: [
    'value',
    'isError',
    'errorMessage'
  ],
  data () {
    return {
      isSubmitButtonEnabled: false
    }
  },
  methods: {
    updateSubmitButton: function (isNhsCompleted) {
      this.isSubmitButtonEnabled = isNhsCompleted
    }
  }
}
</script>
