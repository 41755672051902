<template lang="html">
  <input :type="type || 'button'"
         :value="this.userText"
         :disabled="this.isActive"
         class="ze-button"/>
</template>

<script>
export default {
  props: [
    'text',
    'type',
    'disabled',
    'count'
  ],
  data() {
    return {
      countDown: this.count,
      isActive: this.disabled,
      userText: ''
    }
  },
  created() {
    this.updateUserText();
    this.countDownTimer();
  },
  methods: {
    updateUserText() {
      if(this.countDown > 0) {
        this.userText = this.text + ' (' + this.countDown + ')';
      } else {
        this.userText = this.text;
      }
    },
    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
            this.countDown -= 1
            this.updateUserText();
            this.countDownTimer()
        }, 1000)
      } else {
        this.isActive = false
        this.countDown = ''
      }
    }
  }
}
</script>
